import { Grid, Typography, ThemeProvider, IconButton, createTheme, Button, Slide, Fade, Box, Drawer, List, ListItem, ListItemText, Link, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles/makeStyles';
import './App.css';
import Logo from './assets/logo.png';
import AWS from './assets/aws.png';
import Go from './assets/golang.png';
import React from './assets/react.png';
import NodeJS from './assets/nodejs.png';
import CDK from './assets/aws-cdk.png';
import Serverless from './assets/serverless.png';
import Cert from './assets/aws-certified.png';
import Product from './assets/icons/product.webp';
import Rocket from './assets/icons/rocket.webp';
import Team from './assets/icons/team.webp';
import Support from './assets/icons/support.webp';
import Linkedin from './assets/socials/linkedin.png';
import Carousel from 'react-material-ui-carousel'
// https://lordicon.com/

// 
// Customers
import JDSports from './assets/customers/jd.png';
import Gamma from './assets/customers/gamma.png';

import Hidden from '@mui/material/Hidden';
import { Divide as Hamburger } from 'hamburger-react' //https://hamburger-react.netlify.app/
import { ReactChild, ReactFragment, ReactPortal, useState } from 'react';
import background from './assets/brush.svg';
import { red } from '@mui/material/colors';

var customers = [
  {
      name: "JD Sports Group",
      logo: <JDSports/>
  },
  {
      name: "Gamma Communications",
      description: <Gamma/>
  }
]

// https://www.iconsdb.com/white-icons/support-icon.html
const theme = createTheme({
  palette: {
    background: {
      default: "#0000FF"
    },
    primary: {
      main: "#FFFFFF",
    },
    secondary: {
      main: "#000080",
    },
  },
  typography: {
    fontFamily: '"Montserrat", sans-serif',
    h2: {
      fontFamily: '"Montserrat", sans-serif',
      fontWeight: 'bold'
    },
  },
});

const useStyles = makeStyles({
  logo: {
    margin: '0 auto',
    alignItems: 'left',
    height: '70px',
    width: '160px',
  },
  logoContainer: {
    paddingTop: 25,
  },
  hamburger: {
    paddingTop: 45,
    color: "white",
    paddingLeft: 110
  },
  techLogoContainer: {
    alignItems: 'center',
  },
  techLogo: {
    width: '300px',
    height: '110px',
    paddingBottom: 20,
    maxHeight: '100%',
    maxWidth: '100%'
  },
  cert: {
    height: '300px',
    width: '275px',
    paddingBottom: 10
  },
  certText: {
    paddingTop: 70
  },
  buttonsContainer: {
    paddingTop: 40,
    paddingRight: 1
  },
  drawer: {
    background: 'black',
    color: 'white'
  },
  whoweareimage: {
    margin: '0 auto',
    alignItems: 'left',
    maxWidth: '90px',
    height: '90px',
    width: '90px',
    paddingRight: "90%"
  },
  whoweareimageContainer: {
    align: 'right'
  },
  highlight: {
    background: `url(${background})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 95%',
    padding: '80px 8px',
    // color: 'red'
  },
  menuPanel: {
    height: '100px',
    backgroundColor: 'black'
  },
  welcomeTextMain: {
    padding: 30,
  },
  menuBar: {
    width: '100%',
    height: '120px',
    backgroundColor: '#0000FF'
  },
  welcome: {
    width: '100%',
    height: '500px',
    backgroundColor: '#0000FF'
  },
  whoweare: {
    width: '100%',
    height: '100%',
    backgroundColor: '#0000FF',
    paddingTop: 30
  },
  whoweareTitle: {
    paddingLeft: 80,
  },
  contact: {
    width: '100%',
    height: '600px',
    backgroundColor: '#0000FF'
  },
  footer: {
    width: '100%',
    height: '80px',
    backgroundColor: '#0000FF'
  },
  footerText: {
    paddingTop: 50,
    paddingLeft: 4,
    fontSize: 12,
    color: 'white'
  },
  socials: {
    width: '30px',
    height: '30px',
    paddingTop: 15,
    paddingLeft: 120
  },
  customerCarousel: {
    width: '100%',
    height: '900px'
  },
});

const todaysDate = new Date()
const certText1 = "Jelifish has a team of AWS-certified architects and software engineers. We are experienced builders, with a passion for learning, innovating and continuously improving."
const certText2 = "We work closely with our customers to ensure shared success."

function App() {

  const classes = useStyles();
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false)

  const handleCloseMobMenu = (): void => {
    setMobileMenuOpen(false);
  };
  const handleOpenMobMenu = (): void => {
    setMobileMenuOpen(true);
  };
  const linkedinClick = (): void => {

  }

  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
      <Drawer 
        anchor="top" 
        open={isMobileMenuOpen}
        onClose={handleCloseMobMenu}
        classes={{ paper: classes.drawer }}
      >
      <List>
        {['Get in touch'].map((text, index) => (
          <ListItem button key={text} component={Link} href='mailto:hello@jelifish.co.uk' onClick={() => handleCloseMobMenu()}>
            <ListItemText primary={text}/>
          </ListItem>
        ))}
      </List>
      </Drawer>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0}>
          <Grid container item xs={12} className={classes.menuBar} spacing={0}>
            {/* Desktop View >> */}
            <Hidden mdDown={true}>
              <Grid item md={1}/>
              <Slide 
                  in={true}
                  direction="down"
                  timeout={1000}
                  mountOnEnter unmountOnExit
                >
                  <Grid item container xs={10}>
                    <Grid item xs={3} className={classes.logoContainer}>
                      <img className={classes.logo} src={Logo} alt="Jelifish"/>
                    </Grid>
                    <Grid item xs={6}/>
                    <Grid item xs={3} className={classes.buttonsContainer}>
                      <Button 
                        variant="outlined"  
                        disableRipple
                        size="large"
                        onClick={() => {window.location.href = `mailto:hello@jelifish.co.uk`;}}
                        sx={{
                          padding: "15px 10px",
                          border: "1px solid white",
                          backgroundColor: "#0000FF",
                          color: "white",
                          transition: "background 0.5s, color 0.5s",
                          "&:hover": {
                            backgroundColor: "white",
                            color: "#0000FF"
                          }
                      }}
                      >
                        <Typography>Get In Touch</Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </Slide>
              <Grid item md={1}/>
            </Hidden>
            {/* Desktop View << */}
            {/* Mobile View >> */}
            <Hidden mdUp={true}>
              <Fade 
                in={true}
                timeout={1000}
              >
                <Grid item container xs={12}>
                  <Grid item xs={6} className={classes.logoContainer}>
                    <img className={classes.logo} src={Logo} alt="Jelifish"/>
                  </Grid>
                  <Grid item  xs={6} className={classes.hamburger}>
                    <Hamburger toggled={isMobileMenuOpen} toggle={handleOpenMobMenu}></Hamburger>
                  </Grid>
                </Grid>
              </Fade>
            </Hidden>
          </Grid>   
          {/* << Mobile View */}
        </Grid>
        {/* Welcome Main */}
        <Grid item xs={12} className={classes.welcome} container spacing={0}>
          <Grid item sm={12}/>
          <Grid item sm={3} xs={1}/>
          <Grid item sm={6} xs={10}>
            <Typography className={classes.welcomeTextMain} 
                        color="primary" variant="h2">
                          Scale into <span className={classes.highlight}>your</span> future.
            </Typography>
            <Fade 
              in={true}
              timeout={1000}
            >
              <Typography color="primary">
                Leverage cloud native technology to help your business adopt the agility and
inventiveness of a startup, thrive in a rapidly changing
landscape.
              </Typography>
            </Fade>
          </Grid>
          <Grid item sm={3} xs={1}/>
          <Grid item sm={3} xs={12}/>
        </Grid>
          {/* Who Are We >> */}
          <Slide 
                in={true}
                direction="up"
                timeout={1500}
                mountOnEnter unmountOnExit
              >
            <Grid item container spacing={0} xs={12} className={classes.whoweare}>
              <Grid item sm={1}/>
              <Hidden xsUp={true}>
                <Grid item sm={1}/>     
              </Hidden>
              <Grid item sm={4} xs={10}>
                <Hidden mdUp={true}>
                  <Typography 
                      align="center" 
                      className={classes.whoweareTitle} 
                      color="primary" 
                      variant="h4"
                      sx={{paddingRight: {md:6}}}
                    >
                    How can we help your business?
                  </Typography>
                </Hidden>
                <Hidden mdDown={true}>
                  <Typography 
                      align="left" 
                      className={classes.whoweareTitle} 
                      color="primary" 
                      variant="h4"
                      sx={{paddingRight: {md:6}}}
                    >
                    How can we help your business?
                  </Typography>
                </Hidden>
              </Grid>
              <Hidden xsUp={true}>
                <Grid item sm={1}/>     
              </Hidden>
              <Grid item container spacing={0} sm={6} xs={12}>
                {/* Product Development > */}
                <Grid item sm={6} xs={12} sx={{paddingTop: {xs:4, md:0}, paddingLeft: {xs:4, md:0}}}>
                  <Grid item className={classes.whoweareimageContainer} xs={12}>
                    <img src={Product} className={classes.whoweareimage} alt="Jelifish"/>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography align="left" color="primary" variant="h5">
                      Product Development.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{paddingRight: 20, paddingTop: 20}}>
                    <Typography align="left" color="primary" paragraph>
                      With a team of innovative cloud native software engineers and architects, Jelifish can help get your ideas off the ground quickly while ensuring best practices are built in. 
                    </Typography>
                  </Grid>
                </Grid>
                {/* Modernisation > */}
                <Grid item sm={6} xs={12} sx={{paddingLeft: {xs:4, md:0}}}>
                  <Grid item className={classes.whoweareimageContainer} xs={12}>
                    <img src={Rocket} className={classes.whoweareimage} alt="Jelifish"/>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography align="left" color="primary" variant="h5">
                      Platform Modernisation.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{paddingRight: 20, paddingTop: 20}}>
                    <Typography align="left" color="primary" paragraph>
                      Jelifish can modernise your existing cloud platforms, allowing your business to scale without the burden of outdated technologies and architecture methodologies.
                    </Typography>
                  </Grid>
                </Grid>
                {/* Team Enablement > */}
                <Grid item sm={6} xs={12} sx={{paddingLeft: {xs:4, md:0}}}>
                  <Grid item className={classes.whoweareimageContainer} xs={12}>
                    <img src={Team} className={classes.whoweareimage} alt="Jelifish"/>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography align="left" color="primary" variant="h5">
                      Team Enablement.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{paddingRight: 20, paddingTop: 20}}>
                    <Typography align="left" color="primary" paragraph>
                      We strive to empower your teams; increasing their cloud development skills, reducing the barrier for change and enabling decision making so your business can innovate, faster and safer.
                    </Typography>
                  </Grid>
                </Grid>
                {/* Support > */}
                <Grid item sm={6} xs={12} sx={{paddingLeft: {xs:4, md:0}}}>
                  <Grid item className={classes.whoweareimageContainer} xs={12}>
                    <img src={Support} className={classes.whoweareimage} alt="Jelifish"/>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography align="left" color="primary" variant="h5">
                      Proactive Support.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{paddingRight: 20, paddingTop: 20}}>
                    <Typography align="left" color="primary" paragraph>
                      Jelifish believes in proactive support. We make sure your platforms always benefit from the latest technologies that are tailored to your business and industry sector.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={1}/>
            </Grid>
          </Slide>
          {/* << Who Are We */}
          {/* Customers >> */}
          {/* <Grid item container xs={12}>
            <Grid item xs={12} sx={{paddingTop:12, paddingBottom:3}}>
              <Typography 
                  color="primary" 
                  variant="h4"
                >
                Our Customers.
              </Typography>
            </Grid>
            <Hidden mdDown={true}>
              <Grid item xs={2}/>
            </Hidden>
            <Grid container item sm={8} xs={12}>
              <Carousel
                  className={classes.customerCarousel}
                  fullHeightHover={false} 
              >
              {
                [
                  <img src={JDSports} alt="JD Sports"/>,
                  <img src={Gamma} alt="Gamma"/>
                ]
              }
              </Carousel>
            </Grid> */}
            {/* <Hidden mdDown={true}>
              <Grid item md={2}/>
            </Hidden>
          </Grid> */}
          {/* << Customers */}
          {/* Certifications >> */}
          <Grid item container spacing={0} xs={12}>
            <Grid item xs={12} sx={{paddingTop:12, paddingBottom:3}}>
              <Typography 
                  color="primary" 
                  variant="h4"
                >
                Certified Experts.
              </Typography>
            </Grid>
            <Grid item sm={2} xs={1}/>
            <Grid item container spacing={0} sm={8} xs={10}>
              <Grid item lg={4} sm={6} xs={12}>
                <img src={Cert} className={classes.cert} alt="AWS1"/>
              </Grid>
              <Grid item container lg={8} sm={6} xs={12}>
                <Hidden mdDown={true}>
                  <Typography className={classes.certText} align="left" color="primary" paragraph>
                    {certText1}
                    <br></br>
                    <br></br>
                    {certText2}
                  </Typography>
                </Hidden>
                <Hidden mdUp={true}> 
                  <Typography align="center" color="primary" paragraph>
                    {certText1}
                    <br></br>
                    <br></br>
                    {certText2}
                  </Typography>
                </Hidden>
              </Grid>
            </Grid>
            <Grid item sm={2} xs={1}/>
          </Grid>
          {/* Technologies >> */}
          <Grid item container spacing={0} xs={12}>
            <Grid item xs={12} sx={{paddingTop:5, paddingBottom:5}}>
              <Typography 
                  color="primary" 
                  variant="h4"
                >
                Technologies We Specialise In.
              </Typography>
            </Grid>
            <Grid item sm={2} xs={1}/>
            <Grid item container spacing={0} sm={8} xs={10}>
              <Box sx={{p: 2, border: '1px solid white', width: '100%', flexGrow: 1}}>      
                <Grid item container sx={{padding: 1}} spacing={5} xs={12}>
                  <Grid item lg={4} sm={6} xs={12}>
                    <img src={CDK} className={classes.techLogo} alt="AWS1"/>
                  </Grid>
                  <Grid item lg={4} sm={6} xs={12}>
                    <img src={AWS} className={classes.techLogo} alt="AWS1"/>
                  </Grid>
                  <Grid item lg={4} sm={6} xs={12}>
                    <img src={Serverless} className={classes.techLogo} alt="AWS1"/>
                  </Grid>
                  <Grid item lg={4} sm={6} xs={12}>
                    <img src={NodeJS} className={classes.techLogo} alt="AWS1"/>
                  </Grid>
                  <Grid item lg={4} sm={6} xs={12}>
                    <img src={Go} className={classes.techLogo} alt="AWS1"/>
                  </Grid>
                  <Grid item lg={4} sm={6} xs={12}>
                    <img src={React} className={classes.techLogo} alt="AWS1"/>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item sm={2} xs={1}/>
          </Grid>
          {/* Footer >> */}
          <Grid item container xs={12} className={classes.footer}>
            <Hidden mdDown={true}>
              <Grid item xs={1}/>
            </Hidden>
            <Grid container item sm={10} xs={12}>
              <Grid item xs={4}>
                <div className={classes.footerText}>©{todaysDate.getFullYear()} Jelifish Ltd</div>
              </Grid>
              <Grid item xs={4}/>
              <Grid item xs={4}>
                <div className={classes.socials}>
                  <IconButton style={{ backgroundColor: 'transparent' }} onClick={()=> window.open("https://www.linkedin.com/company/76523882", "_blank")}>
                    <img src={Linkedin} className={classes.socials} alt="social"/>
                  </IconButton>
                </div>
              </Grid>
            </Grid>
            <Hidden mdDown={true}>
              <Grid item md={1}/>
            </Hidden>
          </Grid>
      </Box>
      </div>
    </ThemeProvider>
  );
}

function Customer(props:any)
{
    return (
        <img src={props.item.logo} alt={props.item.name}/>
    )
}

export default App;
